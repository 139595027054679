import { Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useCallback, useEffect, useRef, useState } from "react";
import "./style.css";
import { logout } from "../../actions/auth";
// import OutsideClick from "./OutsideClick";
import { useWindowSize } from "./useWindowSize";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';
import HomeIcon from '@mui/icons-material/Home';
import BottomNavigation from "../BottomNavigation/BottomNavigation";
import AdminBottomNavigation from "../BottomNavigation/BottomNavigation";


const AdminPanel = (props) => {
    const dispatch = useDispatch();

    const boxRef = useRef(null);
    // const windowSize = useWindowSize();
    // let boxOutsideClick = OutsideClick(boxRef);

    const Settings = props.settings;
    const items = Settings.menu_items;
    const { user: currentUser } = useSelector((state) => state.auth);
    const [isSidbarOpen, setSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        checkIsMobile();
    }, [window.innerWidth]);

    useEffect(() => {
        threeDotClicked();
        const updateWindowDimensions = () => {
            checkIsMobile();
        };

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions)

    }, []);

    // useEffect(() => {
    //     if (window.innerWidth < 550) {
    //         setIsMobile(true);
    //     }
    //     else {
    //         setIsMobile(false);
    //     }

    // }, [window.innerWidth]);

    // useEffect(() => {
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [boxRef]);

    function handleClickOutside(event) {
        // console.log("isMobile => " + isMobile);
        if (window.innerWidth > 768)
            return;

        // console.log("second");
        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setSidebarOpen(true);
        } else {
            setSidebarOpen(false);
        }
    }

    function threeDotClicked() {
        setSidebarOpen(!isSidbarOpen);
    }

    function checkIsMobile() {
        if (window.innerWidth < 768) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false);
        }
        setSidebarOpen(false);
    }

    // function getSidebarClassOld() {
    //     // <div class={isSidbarOpen ? "sidebar" : "sidebar close"}>
    //     if (isMobile && isSidbarOpen) {
    //         // writeLog(1);
    //         return "sidebar close";
    //     }
    //     else if (isMobile && !isSidbarOpen) {
    //         // writeLog(2);
    //         return "d-none sidebar close ";
    //     }
    //     else if (!isMobile && isSidbarOpen) {
    //         // writeLog(3);
    //         return "sidebar";
    //     }
    //     else if (!isMobile && !isSidbarOpen) {
    //         // writeLog(4);
    //         return "sidebar close";
    //     }
    // }

    function getSidebarClass() {
        if (isMobile && isSidbarOpen) {
            // writeLog(1);
            return "sidebar sidebarShadow1";
        }
        else if (isMobile && !isSidbarOpen) {
            // writeLog(2);
            return "sidebar";
        }
        else if (!isMobile && isSidbarOpen) {
            // writeLog(3);
            return "sidebar";
        }
        else if (!isMobile && !isSidbarOpen) {
            // writeLog(4);
            return "sidebar close";
        }
    }

    function getHomeSectionClass() {
        if (isMobile && !isSidbarOpen) {
            return "home-section w-100 start-0";
        }
        else if (isMobile && isSidbarOpen) {
            return "home-section w-100 start-0";
        }
        return "home-section";
    }

    function findRole(name) {
        for (let i in currentUser.roles) { //loops over array indexes
            if (currentUser.roles[i] === name) { //case sensitive
                return true;
            }
        }
        return false;
    }

    function overlayClick(e) {
        e.target.style.display = 'none';

        if (isMobile)
            setSidebarOpen(false);
    }

    function getOverlayClass(){
        if(isSidbarOpen && isMobile){
            // toast.info("1");
            return 'd-block';
        }
        else{
            // toast.info("2");
            return 'd-none';
        }
    }

    const logOut = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    return (
        <div>
            <div key={isSidbarOpen} id="overlay" className={getOverlayClass()} onClick={(e) => overlayClick(e)}>

            </div>

            <div class={getSidebarClass()} style={isMobile && !isSidbarOpen ? {width : '0px',} : {}}>
                <div class="logo-details" style={isMobile && !isSidbarOpen ? {display: 'none'} : {}}>
                    <Link to="/" >
                        <img src={require('../../logo.svg').default} width={30} style={{ opacity: .8, marginRight: "22px", marginLeft: "10px" }} />
                    </Link>
                    <span class="logo_name">{Settings.name}</span>
                </div>
                <ul class="nav-links">
                    {items.map((category, i) =>
                        <div>
                            {currentUser.roles && currentUser.roles.indexOf(category.role[0]) != -1 &&
                                <div>
                                    {console.log(currentUser.roles.indexOf(category.role[0]))}
                                    <b><p class={!isMobile && isSidbarOpen ? "mt-3 mb-1 ms-2" : "text-center m-0"} style={{ fontSize: '10px', color: '#ebdada', }}>{!isMobile && isSidbarOpen ? category.name : "----"}</p></b>
                                    {category.links.map((object, s) =>
                                        <li>
                                            <Link to={object.to}>
                                                <i class={object.icon}></i>
                                                <span class="link_name">{object.name}</span>
                                            </Link>
                                        </li>
                                    )}
                                </div>
                            }
                        </div>
                    )}
                </ul>
            </div>

            <section ref={boxRef} class={getHomeSectionClass()}>
                <div class="home-content d-flex justify-content-between">
                    <span class="oi--menu" onClick={() => { threeDotClicked() }}></span>
                    <div class="dropdown ps-4">
                        <img width={"40px"} src={require('./../../profile.webp')} className='dropdown-toggle rounded-circle me-2' data-bs-toggle="dropdown" aria-expanded="false" />
                        <ul class="dropdown-menu">
                            <li><Link to="/profile" className="dropdown-item" >پروفایل</Link></li>
                            {/* <li><a class="dropdown-item" href="#">{location.state.courseId}</a></li> */}
                            <li><a class="dropdown-item" onClick={logOut}>خروج</a></li>
                        </ul>
                    </div>
                </div>
                <div class="main-content" style={{paddingBottom: '60px'}}>
                    <Outlet />
                    <AdminBottomNavigation />
                </div>

            </section>

            <ToastContainer position="top-left" rtl />
        </div>
    );
};

export default AdminPanel;
